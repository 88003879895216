export const API_LOADING = 'API_LOADING';
export const API_RETURN_MSG = 'API_RETURN_MSG';
export const API_CHANGE_VALUE = 'API_CHANGE_VALUE';
export const API_RESET = 'API_RESET';
export const API_EDIT = 'API_EDIT';
export const API_PUSHED = 'API_PUSHED';
export const DELETE = 'DELETE';
export const CREATE_UPDATE = 'CREATE_UPDATE';
export const LIST = 'LIST';
export const GET = 'GET';
export const SCAN = 'SCAN';
export const COLOR_MODE = 'COLOR_MODE';
export const UNIVERSAL = 'UNIVERSAL';
export const STATUS = 'STATUS';
export const RESET_STATUS = 'RESET_STATUS';
export const USER_APPEND_VALUE = 'USER_APPEND_VALUE';
export const SNACK_OPEN = 'SNACK_OPEN';
export const RESETLIST = 'RESETLIST';
export const RELOAD_API = 'RELOAD_API';
